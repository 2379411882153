<template>
    <data-grid
        class="!rounded-none border-l-0 border-r-0 border-t-0"
        :data-source="entities.data"
        :columns="columns"
        :loading="loading"
        selectable
        identify-field="id"
        @row-click="onRowClick"
        :pre-selected-items="selectedItems"
    >
        <template #outer-header>
            <div class="flex flex-1 flex-col">
                <div
                    class="flex w-full items-start justify-between gap-2 px-6 py-5"
                >
                    <div class="flex flex-col gap-1">
                        <div class="text-lg font-medium text-gray-900">
                            {{ $t('bookeepingAccounts.title') }}
                        </div>
                        <div class="text-sm font-normal text-gray-500">
                            {{ $t('bookeepingAccounts.description') }}
                        </div>
                    </div>
                    <base-button
                        v-if="$acl.can('create_chart_of_accounts')"
                        variant="primary"
                        icon="line-icons:general:plus"
                        :label="$t('general.addNew')"
                        @click="onShowAddModal"
                    />
                </div>

                <div
                    class="flex w-full justify-between border-b border-t border-gray-200 px-4 py-3"
                >
                    <div class="grow">
                        <bookeeping-accounts-list-types />
                    </div>

                    <div class="flex grow">
                        <bookeeping-accounts-filter-search />
                    </div>
                </div>

                <div class="border-b px-4 py-3">
                    <base-button
                        v-if="
                            isNil(archivedQuery) &&
                            $acl.can('delete_chart_of_accounts')
                        "
                        @click="showBulkDeleteModal = true"
                        variant="default"
                        :disabled="!selectedItems.length"
                    >
                        {{ $t('general.delete') }}
                    </base-button>

                    <base-button
                        v-if="
                            !isNil(archivedQuery) &&
                            $acl.can('update_chart_of_accounts')
                        "
                        variant="default"
                        :disabled="!selectedItems.length"
                        @click="onRestore"
                        :loading="isLoadingRestore"
                    >
                        {{ $t('bookeepingAccounts.restore') }}
                    </base-button>
                </div>
            </div>
        </template>

        <template #header-select-all>
            <form-checkbox v-model="isSelectedAll" />
        </template>

        <template #column-type="{ item }">
            {{ getType(item)?.name }}
        </template>

        <template #column-detailType="{ item }">
            {{ getDetailType(item)?.name }}
        </template>

        <template v-if="entities.currency" #column-cybooksBalance="{ item }">
            {{ $filters.currency(item.closing_balance, entities.currency.iso) }}
        </template>

        <template #column-tax="{ item }">
            {{ item.tax_rule?.name }}
        </template>

        <template #column-checkbox="{ item }">
            <base-tooltip
                v-if="!item.deletable"
                dark
                hover
                :content="$t('bookeepingAccounts.tooltip.deletable')"
            >
                <base-icon
                    variant="inherit"
                    name="line-icons:security:lock-01"
                />
            </base-tooltip>
            <form-checkbox v-else v-model="selectedItems" :value="item" />
        </template>

        <template v-if="entities.currency" #column-bankBalance="{ item }">
            <span v-if="item.balance">
                {{ $filters.currency(item.balance, entities.currency.iso) }}
            </span>
        </template>

        <template #column-action="{ item }">
            <base-context-menu>
                <template #trigger="{ toggleMenu }">
                    <div class="pr-4" @click="toggleMenu">
                        <base-icon
                            variant="grayLight"
                            name="line-icons:general:dots-vertical"
                        />
                    </div>
                </template>

                <base-context-menu-item
                    v-if="$acl.can('update_chart_of_accounts')"
                    icon="line-icons:general:edit-01"
                    @click="onShowEdit(item)"
                >
                    {{ $t('general.edit') }}
                </base-context-menu-item>
                <base-context-menu-item
                    v-if="
                        item.deletable &&
                        item.journal_lines.length === 0 &&
                        $acl.can('delete_chart_of_accounts')
                    "
                    icon="line-icons:general:trash-01"
                    @click="onShowDelete(item)"
                >
                    {{ $t('general.delete') }}
                </base-context-menu-item>
            </base-context-menu>
        </template>

        <template #footer>
            <grid-pagination
                :total="entities.last_page"
                :page="entities.current_page"
            />
        </template>
    </data-grid>

    <bookeeping-accounts-add-modal
        :show="state.showAddModal"
        @modal-close="onCloseAddModal"
        @on-finished="onFinishUpsert"
    />
    <bookeeping-accounts-edit-modal
        :show="state.showEditModal"
        :chartOfAccount="entity"
        @modal-close="onCloseEdit"
        @on-finished="onFinishUpsert"
    />

    <base-delete-modal
        size="md"
        :show="state.showDeleteModal"
        :loading="loadingBulkDelete"
        :name="entity?.name"
        @modal-close="onCloseDelete"
        @confirm-delete="confirmDelete"
    />

    <bookeeping-accounts-delete-modal
        :show="showBulkDeleteModal"
        :loading="loadingBulkDelete"
        :selected-items="selectedItems"
        @modal-close="showBulkDeleteModal = false"
        @confirm-delete="confirmBulkDelete"
    />
</template>
<script setup>
import { isNil } from 'lodash-es'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { entities, entity, useApiSearch, setEntity } = useApiFactory(
    'chart-of-accounts/flat'
)
const { loading, executeRefreshSearch } = useApiSearch()

useHead({
    title: t('bookeepingAccounts.title'),
})

const state = reactive({
    showAddModal: false,
    showEditModal: false,
    showDeleteModal: false,
})

const columns = [
    {
        property: 'code',
        label: t('bookeepingAccounts.headerAccountNo'),
        headerCellClass: 'w-[120px]',
        dataCellClass: '!text-gray-500',
    },
    {
        property: 'name',
        label: t('bookeepingAccounts.headerName'),
        dataCellClass: '!text-gray-500',
    },
    {
        property: 'type',
        label: t('bookeepingAccounts.headerType'),
        dataCellClass: '!text-gray-500',
    },
    {
        property: 'detailType',
        label: t('bookeepingAccounts.headerDetailType'),
        dataCellClass: '!text-gray-500',
    },
    {
        property: 'tax',
        label: t('bookeepingAccounts.headerTax'),
        headerCellClass: '!rounded-none',
        dataCellClass: '!text-gray-500',
    },
    {
        property: 'cybooksBalance',
        label: t('bookeepingAccounts.headerCybooksBalance'),
        dataCellClass: '!text-gray-500',
    },
]

const archivedQuery = computed(() => route.query.archived)

const getType = (item) => {
    return item.ancestors.find((i) => i.parent_id === null)
}

const getDetailType = (item) => {
    return item.ancestors.find((i) => i.parent_id !== null)
}

const onShowAddModal = () => {
    state.showAddModal = true
}

const onCloseAddModal = () => {
    state.showAddModal = false
}

const onShowEdit = (item) => {
    setEntity(item)
    state.showEditModal = true
}

const onCloseEdit = () => {
    setEntity(null)
    state.showEditModal = false
}

const onShowDelete = (item) => {
    setEntity(item)
    state.showDeleteModal = true
}

const onCloseDelete = () => {
    setEntity(null)
    state.showDeleteModal = false
}

const { invalidate } = useFlatChartOfAccounts(1)
const onFinishUpsert = () => {
    onCloseAddModal()
    onCloseEdit()
    executeRefreshSearch()
    invalidate()
}

const confirmDelete = () => {
    executeBulkDelete({
        data: {
            ids: [entity.value.id],
        },
    }).then(() => {
        onCloseDelete()
        executeRefreshSearch()
    })
}

const onRowClick = (id) => {
    router.push({
        name: 'bookeeping.accounts.detail',
        params: { id },
    })
}

const selectedItems = ref([])

const isSelectedAll = ref(false)
watch(
    () => isSelectedAll.value,
    (value) => {
        if (value) {
            selectedItems.value = entities.value.data.filter(
                (item) => item.deletable
            )
        } else {
            selectedItems.value = []
        }
    }
)

// BULK DELETE
const showBulkDeleteModal = ref(false)
const { execute: executeBulkDelete, loading: loadingBulkDelete } = useApi(
    '/api/chart-of-accounts',
    'DELETE'
)

const { errorNotify, successNotify } = useNotification()
const confirmBulkDelete = async () => {
    try {
        await executeBulkDelete({
            data: {
                ids: selectedItems.value.map((item) => item.id),
            },
        })

        showBulkDeleteModal.value = false
        successNotify({
            text: t('bookeepingAccounts.deleteSuccess'),
        })
        await executeRefreshSearch()
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    }
}
// END: BULK DELETE

// RESTORE ACCOUNTS
const { execute: executeRestore, loading: isLoadingRestore } = useApi(
    '/api/chart-of-accounts/restore',
    'POST'
)

const onRestore = async () => {
    try {
        await executeRestore({
            data: {
                ids: selectedItems.value.map((item) => item.id),
            },
        })

        successNotify({
            text: t('bookeepingAccounts.restoreSuccess'),
        })
        await executeRefreshSearch()
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    }
}
// END: RESTORE ACCOUNTS
</script>
