<template>
    <form-text-input
        size="sm"
        icon-name="line-icons:general:search-lg"
        :placeholder="$t('bookeepingAccounts.searchText')"
        :model-value="filterValues.name"
        @input="onChange($event.target.value)"
    >
        {{ $t('general.search') }}
    </form-text-input>
</template>

<script setup>
import { useDebounceFn } from '@vueuse/core'

const queries = {
    name: 'q',
}
const { filterValues, updateFilter } = useFilters(queries)

const onChange = useDebounceFn(
    (value) => updateFilter(queries.name, value),
    300
)
</script>
