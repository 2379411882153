<template>
    <base-modal
        close
        :show="show"
        size="auto"
        class="w-[800px]"
        :loading="loadingCreate"
        @modal-close="closeModal"
    >
        <template #modal-header>
            <h6 class="text-lg font-semibold text-gray-900">
                {{ $t('bookeepingAccounts.upsertModal.title') }}
            </h6>
        </template>

        <bookeeping-account-chart-of-account-form
            :type="type"
            :chart-of-account="chartOfAccount"
            :custom-init-value="customInitValue"
            :disabled-type="!!accountType && !!chartOfAccount"
            :disabled-detail-type="!!accountDetailType && !!chartOfAccount"
        />

        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button full-width variant="primary" @click="onSubmit">
                    {{ $t('bookeepingAccounts.upsertModal.createAndClose') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close', 'on-finished'])

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        required: false,
        default: null,
    },
    chartOfAccount: {
        type: Object,
        required: false,
        default: null,
    },
})

const { execute: executeCreate, loading: loadingCreate } = useApi(
    '/api/chart-of-accounts',
    'POST'
)

const { handleSubmit, resetForm, setErrors, setValues } = useForm()

const { value: accountType } = useField('account_type')
const { value: accountDetailType } = useField('parent_id')

const closeModal = () => {
    emit('modal-close')
}

const onSubmit = handleSubmit((values) => {
    executeCreate({ data: values })
        .then((chartOfAccount) => {
            resetForm()
            emit('on-finished', chartOfAccount)
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
})

const customInitValue = () => {
    const { chartOfAccount } = props

    const accountType = (chartOfAccount?.ancestors || []).find(
        (i) => i.parent_id === null
    )

    setValues({
        account_type: accountType?.id,
    })

    nextTick(() => {
        setValues({
            parent_id: chartOfAccount?.id,
        })
    })
}
</script>
