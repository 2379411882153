<template>
    <base-dropdown size="sm" variant="default" class="bg-white">
        <template #btn-content>
            {{ typeName }}

            <base-icon
                name="line-icons:arrows:chevron-down"
                variant="inherit"
            />
        </template>

        <template #default="{ toggleDropdown }">
            <base-dropdown-item
                @click="onChangeType(undefined, toggleDropdown)"
            >
                {{ $t('bookeepingAccounts.detail.allAccounts') }}
            </base-dropdown-item>

            <base-dropdown-item
                @click="
                    onChangeType(CHART_OF_ACCOUNT_TYPES.asset, toggleDropdown)
                "
            >
                {{
                    $t('general.coaTypes', {
                        type: CHART_OF_ACCOUNT_TYPES.asset,
                    })
                }}
            </base-dropdown-item>

            <base-dropdown-item
                @click="
                    onChangeType(
                        CHART_OF_ACCOUNT_TYPES.liability,
                        toggleDropdown
                    )
                "
            >
                {{
                    $t('general.coaTypes', {
                        type: CHART_OF_ACCOUNT_TYPES.liability,
                    })
                }}
            </base-dropdown-item>

            <base-dropdown-item
                @click="
                    onChangeType(CHART_OF_ACCOUNT_TYPES.equity, toggleDropdown)
                "
            >
                {{
                    $t('general.coaTypes', {
                        type: CHART_OF_ACCOUNT_TYPES.equity,
                    })
                }}
            </base-dropdown-item>

            <base-dropdown-item
                @click="
                    onChangeType(CHART_OF_ACCOUNT_TYPES.income, toggleDropdown)
                "
            >
                {{
                    $t('general.coaTypes', {
                        type: CHART_OF_ACCOUNT_TYPES.income,
                    })
                }}
            </base-dropdown-item>

            <base-dropdown-item
                @click="
                    onChangeType(CHART_OF_ACCOUNT_TYPES.expense, toggleDropdown)
                "
            >
                {{
                    $t('general.coaTypes', {
                        type: CHART_OF_ACCOUNT_TYPES.expense,
                    })
                }}
            </base-dropdown-item>
        </template>
    </base-dropdown>
</template>
<script setup>
import { CHART_OF_ACCOUNT_TYPES } from '@tenant/modules/tenant/bookeeping-accounts/utils/constants'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const type = computed(() => route.query.type)

const typeName = computed(() => {
    if (!type.value) {
        return t('bookeepingAccounts.detail.allAccounts')
    }

    return t('general.coaTypes', { type: type.value })
})

const onChangeType = (type, toggleDropdown) => {
    router.replace({
        query: {
            type,
        },
    })

    toggleDropdown()
}
</script>
