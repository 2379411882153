import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'bookeeping.accounts',
        path: '/bookeeping/accounts',
        redirect: {
            name: 'bookeeping.accounts.list',
        },
        children: [
            {
                name: 'bookeeping.accounts.list',
                path: 'list',
                component: () => import('./pages/bookeeping-accounts-list.vue'),
            },
            {
                name: 'bookeeping.accounts.detail',
                path: ':id',
                component: () =>
                    import('./pages/bookeeping-accounts-detail.vue'),
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'view_chart_of_accounts',
    },
}
