<template>
    <base-modal
        close
        :show="show"
        size="auto"
        class="w-[800px]"
        :loading="loadingEdit"
        @modal-close="closeModal"
    >
        <template #modal-header>
            <h6 class="text-lg font-semibold text-gray-900">
                {{ $t('bookeepingAccounts.upsertModal.title') }}
            </h6>
        </template>

        <bookeeping-account-chart-of-account-form
            :chartOfAccount="chartOfAccount"
            :disabled-type="chartOfAccount && !chartOfAccount.deletable"
        />

        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button full-width variant="primary" @click="onSubmit">
                    {{ $t('bookeepingAccounts.upsertModal.updateAndClose') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close', 'on-finished'])

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    chartOfAccount: {
        type: Object,
        default: null,
    },
})

const { execute: executeEdit, loading: loadingEdit } = useApi(
    '/api/chart-of-accounts/{id}',
    'PUT'
)

const { handleSubmit, resetForm, setErrors } = useForm()

const closeModal = () => {
    emit('modal-close')
}

const onSubmit = handleSubmit((values) => {
    executeEdit({
        data: values,
        params: { id: props.chartOfAccount.id },
    })
        .then((chartOfAccount) => {
            resetForm()
            emit('on-finished', chartOfAccount)
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
})
</script>
