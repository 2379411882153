<template>
    <div class="h-full">
        <div class="flex h-[calc(100%+64px)]">
            <!-- Sidebar/Left -->
            <base-compact-list
                :items="accounts"
                :is-loading="isLoadingAccounts"
                :initial-selected-item="selectedAccount"
                @item-click="onSidebarItem"
                @load-more="onLoadMore"
            >
                <template #header>
                    <!-- Select filter -->
                    <bookeeping-accounts-detail-types />

                    <!-- Add new account -->
                    <base-button
                        v-if="$acl.can('create_chart_of_accounts')"
                        icon="line-icons:general:plus"
                        :label="$t('bookeepingAccounts.detail.addNew')"
                        size="sm"
                        @click="onToggleAddModal"
                    />
                </template>

                <template #item-content="{ item, clickItem }">
                    <div
                        class="flex w-full items-start gap-5"
                        @click="clickItem(item)"
                    >
                        <!-- Deletable icon -->
                        <base-tooltip
                            dark
                            hover
                            :content="
                                $t('bookeepingAccounts.tooltip.deletable')
                            "
                        >
                            <base-icon
                                v-if="!item.deletable"
                                variant="inherit"
                                name="line-icons:security:lock-01"
                            />
                        </base-tooltip>

                        <!-- Account item details -->
                        <div>
                            <p class="text-sm text-primary-600">
                                {{ item?.name }}
                            </p>
                            <p class="text-xs text-gray-500">
                                {{ getDetailType(item)?.name }}
                            </p>
                        </div>
                    </div>
                </template>
            </base-compact-list>

            <!-- Main/Right -->
            <div
                class="flex h-full grow flex-col"
                v-if="!isLoadingChartOfAccountDetail"
            >
                <!-- Top bar -->
                <div class="flex justify-between border-b p-6 pb-3">
                    <div v-if="selectedAccount">
                        <p class="text-xs text-gray-500">
                            {{ getDetailType(selectedAccount)?.name }}
                        </p>
                        <div class="font-semibold text-gray-700">
                            {{ selectedAccount?.name }}
                        </div>
                    </div>

                    <!-- Edit button -->
                    <base-button
                        v-if="$acl.can('update_chart_of_accounts')"
                        icon="line-icons:editor:pencil-01"
                        :label="$t('general.edit')"
                        size="sm"
                        variant="default"
                        @click="onToggleEditModal"
                    />
                </div>

                <!-- Header -->
                <div class="border-b-2 bg-gray-100 p-6">
                    <template v-if="entities?.currency?.iso">
                        <p class="mb-1 text-lg text-gray-700">
                            {{ $t('bookeepingAccounts.detail.closingBalance') }}
                        </p>
                        <div class="mb-1 text-2xl font-medium">
                            {{
                                $filters.currency(
                                    selectedAccount?.closing_balance ?? 0,
                                    entities.currency.iso
                                )
                            }}
                        </div>
                    </template>
                    <p class="text-sm text-gray-600">
                        {{ selectedAccount?.description }}
                    </p>
                </div>

                <!-- Data-grid -->
                <div class="grow overflow-auto">
                    <data-grid
                        class="!rounded-none border-0"
                        :data-source="currentJournalLines"
                        :columns="columns"
                    >
                        <template #column-date="{ item }">
                            {{
                                $filters.dateCustomFormat(
                                    item.journal_entry.date,
                                    'DD MMM YYYY'
                                )
                            }}
                        </template>

                        <template #column-transactionDetails="{ item }">
                            {{ item.journal_entry.transaction_details }}
                        </template>

                        <template #column-transactionType="{ item }">
                            {{ item.journal_entry.transaction_type }}
                        </template>

                        <template #column-debit="{ item }">
                            {{
                                item.debit
                                    ? $filters.numberFormat(item.debit)
                                    : ''
                            }}
                        </template>

                        <template #column-credit="{ item }">
                            {{
                                item.credit
                                    ? $filters.numberFormat(item.credit)
                                    : ''
                            }}
                        </template>
                    </data-grid>
                    <span
                        class="cursor-pointer px-6 py-4 text-primary-700"
                        @click="onShowMoreDetail(selectedAccount.id)"
                    >
                        {{ $t('bookeepingAccounts.detail.show_details') }}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <!-- Add new -->
    <bookeeping-accounts-add-modal
        :show="showAddModal"
        @modal-close="onToggleAddModal"
        @on-finished="onFinishUpsert"
    />

    <!-- Edit current -->
    <bookeeping-accounts-edit-modal
        :show="showEditModal"
        :chartOfAccount="entity"
        @modal-close="onToggleEditModal"
        @on-finished="onFinishEdit"
    />
</template>

<script setup>
import { useInfiniteQuery } from '@tanstack/vue-query'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()

// remove and use entity
const currentJournalLines = ref([])

const {
    execute: getChartOfAccountDetail,
    result: selectedAccount,
    loading: isLoadingChartOfAccountDetail,
} = useApi('api/chart-of-accounts/{id}', 'GET')

onMounted(() => {
    getChartOfAccountDetail({
        params: {
            id: route.params.id,
            type: route.params.type,
        },
    })
})

// Items in sidebar
const { entities, useApiSearch, entity, setEntity } = useApiFactory(
    'chart-of-accounts/flat'
)
const { execute } = useApiSearch()

watch(
    () => selectedAccount.value,
    () => {
        setEntity(selectedAccount.value)
        currentJournalLines.value = selectedAccount.value?.journal_lines ?? []
    }
)

const onShowMoreDetail = (id) => {
    router.push({
        name: 'reports.account-transactions',
        query: {
            chart_of_account_id: id,
        },
    })
}

const onSidebarItem = (item) => {
    if (item.id === selectedAccount.value?.id) {
        getChartOfAccountDetail({
            params: {
                id: item.id,
            },
        })

        return
    }

    selectedAccount.value = accounts.value?.find(
        (account) => account.id === item.id
    )

    router.replace({
        params: { id: item.id },
        query: {
            type: item.type,
        },
    })
}

watch(
    () => route.params.id,
    () => {
        if (!route.params.id) return
        if (route.name !== 'bookeeping.accounts.detail') return

        getChartOfAccountDetail({
            params: {
                id: route.params.id,
            },
        })
    }
)
const getDetailType = (item) => {
    return item.ancestors.find((i) => i.parent_id !== null)
}

// Add modal
const showAddModal = ref(false)

const onToggleAddModal = () => {
    showAddModal.value = !showAddModal.value
}

// Edit modal
const showEditModal = ref(false)

const onToggleEditModal = () => {
    showEditModal.value = !showEditModal.value
}

// Update values from modal
const onFinishUpsert = () => {
    showAddModal.value = false
    showEditModal.value = false

    // Re-fetch sidebar data
    refetch()
}

const onFinishEdit = (chartOfAccount) => {
    getChartOfAccountDetail({
        params: {
            id: route.params.id,
        },
    })

    onFinishUpsert(chartOfAccount)
}

// Table Columns
const columns = ref([
    {
        property: 'date',
        label: t('bookeepingAccounts.detail.dateLabel'),
        headerCellClass: '!rounded-none uppercase font-normal',
        dataCellClass: '!text-gray-700',
    },
    {
        property: 'transactionDetails',
        label: t('bookeepingAccounts.detail.transactionDetailsLabel'),
        headerCellClass: 'uppercase font-normal',
        dataCellClass: '!text-gray-700',
    },
    {
        property: 'transactionType',
        label: t('bookeepingAccounts.detail.transactionTypeLabel'),
        headerCellClass: 'uppercase font-normal',
        dataCellClass: '!text-gray-700',
    },
    {
        property: 'debit',
        label: t('bookeepingAccounts.detail.debitLabel'),
        headerCellClass: 'uppercase font-normal',
        dataCellClass: '!text-primary-600 font-semibold',
    },
    {
        property: 'credit',
        label: t('bookeepingAccounts.detail.creditLabel'),
        dataCellClass: '!text-primary-600 font-semibold',
        headerCellClass: '!rounded-none uppercase font-normal',
    },
])

// INFINITE SCROLL ACCOUNT LIST
const {
    data: accounts,
    isFetching: isLoadingAccounts,
    fetchNextPage: fetchMoreAccounts,
    hasNextPage: hasMoreAccounts,
    refetch,
} = useInfiniteQuery({
    queryKey: ['bookeeping-accounts'],
    queryFn: ({ pageParam = 1 }) => {
        return execute({
            page: pageParam,
            limit: 20,
            ...route.query,
        })
    },
    getNextPageParam: ({ current_page, last_page }) => {
        return current_page < last_page ? current_page + 1 : undefined
    },
    select: (res) =>
        uniqBy(
            res?.pages?.flatMap((x) => x?.data),
            (x) => x?.id
        ),
})

import { uniqBy } from 'lodash-es'

// INFINITE SCROLL ACCOUNT LIST
const onLoadMore = () => {
    if (hasMoreAccounts.value) {
        fetchMoreAccounts()
    }
}
watch(
    () => route.query.chart_of_account,
    () => {
        refetch()
    }
)
watch(
    () => route.query.type,
    () => {
        refetch()
    }
)
// END: INFINITE SCROLL ACCOUNT LIST
</script>
