const navigation = [
    {
        id: 'sas-bookeeping-accounts',
        path: 'bookeeping.accounts',
        label: 'bookeepingAccounts.title',
        parent: 'sas-bookeeping',
        position: 3,
        permission: 'view_chart_of_accounts',
    },
]

export default navigation
