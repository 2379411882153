<template>
    <base-button-group>
        <base-button
            variant="default"
            :class="{
                'bg-gray-100': isEmpty(type) && archived !== '1',
            }"
            @click="onChangeType(undefined)"
        >
            {{ $t('general.viewAll') }}
        </base-button>
        <base-button
            variant="default"
            :class="{
                'bg-gray-100': type === CHART_OF_ACCOUNT_TYPES.asset,
            }"
            @click="onChangeType(CHART_OF_ACCOUNT_TYPES.asset)"
        >
            {{ $t('general.coaTypes', { type: CHART_OF_ACCOUNT_TYPES.asset }) }}
        </base-button>
        <base-button
            variant="default"
            :class="{
                'bg-gray-100': type === CHART_OF_ACCOUNT_TYPES.liability,
            }"
            @click="onChangeType(CHART_OF_ACCOUNT_TYPES.liability)"
        >
            {{
                $t('general.coaTypes', {
                    type: CHART_OF_ACCOUNT_TYPES.liability,
                })
            }}
        </base-button>
        <base-button
            variant="default"
            :class="{
                'bg-gray-100': type === CHART_OF_ACCOUNT_TYPES.equity,
            }"
            @click="onChangeType(CHART_OF_ACCOUNT_TYPES.equity)"
        >
            {{
                $t('general.coaTypes', { type: CHART_OF_ACCOUNT_TYPES.equity })
            }}
        </base-button>
        <base-button
            variant="default"
            :class="{
                'bg-gray-100': type === CHART_OF_ACCOUNT_TYPES.income,
            }"
            @click="onChangeType(CHART_OF_ACCOUNT_TYPES.income)"
        >
            {{
                $t('general.coaTypes', { type: CHART_OF_ACCOUNT_TYPES.income })
            }}
        </base-button>
        <base-button
            variant="default"
            :class="{
                'bg-gray-100': type === CHART_OF_ACCOUNT_TYPES.expense,
            }"
            @click="onChangeType(CHART_OF_ACCOUNT_TYPES.expense)"
        >
            {{
                $t('general.coaTypes', { type: CHART_OF_ACCOUNT_TYPES.expense })
            }}
        </base-button>

        <base-button
            variant="default"
            :class="{
                'bg-gray-100': archived === '1',
            }"
            @click="onArchiveType"
        >
            {{ $t('general.archive') }}
        </base-button>
    </base-button-group>
</template>
<script setup>
import { isEmpty } from 'lodash-es'
import { CHART_OF_ACCOUNT_TYPES } from '@tenant/modules/tenant/bookeeping-accounts/utils/constants'

const router = useRouter()
const route = useRoute()

const type = computed(() => route.query.type)
const archived = computed(() => `${route.query.archived}`)

const onChangeType = (type) => {
    router.replace({
        query: {
            archived: undefined,
            type,
        },
    })
}

const onArchiveType = () => {
    router.replace({
        query: {
            archived: 1,
        },
    })
}
</script>
