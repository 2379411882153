<template>
    <base-delete-modal
        size="md"
        :show="show"
        :loading="loading"
        @modal-close="closeModal"
        @confirm-delete="confirmDelete"
    >
        <template #title>
            <span>
                {{
                    $t(
                        'bookeepingAccounts.deleteModal.bulkDeleteTitle',
                        selectedItems.length
                    )
                }}
            </span>
        </template>

        <template #description>
            <div class="flex flex-col gap-2.5">
                {{
                    $t(
                        'bookeepingAccounts.deleteModal.bulkFirstText',
                        selectedItems.length
                    )
                }}

                <div v-if="selectedDeletableCount" class="flex gap-2">
                    <base-badge
                        variant="gray"
                        :label="selectedDeletableCount"
                    />

                    <span
                        v-html="
                            $t(
                                'bookeepingAccounts.deleteModal.bulkDeleteText',
                                selectedDeletableCount.length
                            )
                        "
                    ></span>
                </div>

                <div v-if="selectedUnDeletableCount" class="flex gap-2">
                    <base-badge
                        variant="gray"
                        :label="selectedUnDeletableCount"
                    />

                    <span
                        v-html="
                            $t(
                                'bookeepingAccounts.deleteModal.bulkArchiveText',
                                selectedUnDeletableCount.length
                            )
                        "
                    ></span>
                </div>

                {{ $t('bookeepingAccounts.deleteModal.bulkSecondText') }}

                <hr class="my-2" />

                <span
                    v-html="$t('bookeepingAccounts.deleteModal.bulkNoteText')"
                ></span>
            </div>
        </template>
    </base-delete-modal>
</template>
<script setup>
const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    selectedItems: {
        type: Array,
        default: () => [],
    },
})

const emit = defineEmits(['modal-close', 'modal-delete'])

const closeModal = () => {
    emit('modal-close')
}

const confirmDelete = () => {
    emit('modal-delete')
}

const selectedDeletableCount = computed(() => {
    return props.selectedItems.filter((item) => item.journal_lines.length === 0)
        .length
})

const selectedUnDeletableCount = computed(() => {
    return props.selectedItems.length - selectedDeletableCount.value
})
</script>
