<template>
    <div class="flex flex-row gap-5">
        <div class="flex flex-1 flex-col gap-4">
            <form-single-select
                size="sm"
                :label="$t('bookeepingAccounts.upsertModal.accountTypeLabel')"
                v-model="accountType"
                :disabled="disabledType"
                :show-clear="!disabledType"
                :error-message="errorAccountType"
                :options="accountTypeOptions"
            />
            <form-single-select
                size="sm"
                :label="$t('bookeepingAccounts.upsertModal.detailTypeLabel')"
                v-model="detailTypeId"
                :disabled="disabledDetailType"
                :error-message="errorDetailTypeId"
                :options="detailTypeOptions"
            />
            <p
                v-if="detailType?.description"
                class="rounded-lg border border-gray-300 bg-gray-50 px-3.5 py-2.5 text-gray-500"
                v-html="detailType?.description"
            />
        </div>
        <div class="flex flex-1 flex-col gap-4">
            <form-text-input
                size="sm"
                v-model="name"
                :label="$t('bookeepingAccounts.upsertModal.nameLabel')"
                :placeholder="$t('bookeepingAccounts.upsertModal.nameLabel')"
                :error-message="errorName"
            />
            <form-text-input
                size="sm"
                v-model="code"
                :label="$t('bookeepingAccounts.upsertModal.code')"
                :placeholder="$t('bookeepingAccounts.upsertModal.code')"
                :error-message="errorCode"
            />
            <form-text-input
                size="sm"
                v-model="description"
                :label="$t('bookeepingAccounts.upsertModal.descriptionLabel')"
                :placeholder="
                    $t('bookeepingAccounts.upsertModal.descriptionLabel')
                "
                :error-message="errorDescription"
            />
            <form-single-select
                v-if="!detailType?.tax_rule_id"
                size="sm"
                v-model="taxRule"
                :label="$t('bookeepingAccounts.upsertModal.taxLabel')"
                :placeholder="
                    $t('bookeepingAccounts.upsertModal.taxPlaceholder')
                "
                :options="taxRules"
                option-value="id"
                option-label="name"
                position="top"
                :hint-text="$t('bookeepingAccounts.upsertModal.taxHint')"
                :error-message="errorTaxRule"
            />
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    type: {
        type: String,
        default: null,
    },
    chartOfAccount: {
        type: Object,
        default: null,
    },
    disabledType: {
        type: Boolean,
        default: false,
    },
    disabledDetailType: {
        type: Boolean,
        default: false,
    },
    customInitValue: {
        type: Function,
        default: null,
    },
})

const { taxRules } = useTaxRules()

const { execute: executeGetTree, result } = useApi(
    '/api/chart-of-accounts/tree',
    'GET'
)

const {
    value: accountType,
    errorMessage: errorAccountType,
    setValue: setAccountType,
} = useField('account_type')
const {
    value: detailTypeId,
    errorMessage: errorDetailTypeId,
    setValue: setDetailTypeId,
} = useField('parent_id')
const {
    value: name,
    errorMessage: errorName,
    setValue: setName,
} = useField('name')
const {
    value: code,
    errorMessage: errorCode,
    setValue: setCode,
} = useField('code')
const {
    value: description,
    errorMessage: errorDescription,
    setValue: setDescription,
} = useField('description')
const {
    value: taxRule,
    errorMessage: errorTaxRule,
    setValue: setTaxRule,
} = useField('tax_rule_id')

const selectedType = computed(() => {
    return (result.value || []).find(
        (option) => option.id === accountType.value
    )
})

const accountTypeOptions = computed(() => {
    let values = result.value || []
    if (props.type) {
        values = values.filter((i) => i.type === props.type)
    }

    return values.map((item) => ({
        value: item.id,
        label: item.name,
    }))
})

const detailTypeOptions = computed(() => {
    if (!selectedType.value) {
        return []
    }

    return (selectedType.value?.children || []).map((item) => ({
        value: item.id,
        label: item.name,
    }))
})

const detailType = computed(() => {
    if (!detailTypeId.value) {
        return null
    }

    return (selectedType.value?.children || []).find(
        (i) => i.id === detailTypeId.value
    )
})

watch(
    () => accountType.value,
    (value) => {
        setDetailTypeId(
            value ? (detailTypeOptions.value || [])[0]?.value : null
        )
    }
)

onMounted(() => {
    executeGetTree({
        queries: {
            depth: 1,
        },
    }).then(initValues)
})

const initValues = () => {
    const { chartOfAccount, customInitValue } = props
    if (customInitValue) {
        customInitValue()

        return
    }

    if (!chartOfAccount) {
        return
    }

    const accountType = (chartOfAccount.ancestors || []).find(
        (i) => i.parent_id === null
    )

    setAccountType(accountType?.id)
    setName(chartOfAccount.name)
    setCode(chartOfAccount.code)
    setTaxRule(chartOfAccount.tax_rule_id)
    setDescription(chartOfAccount.description)

    nextTick(() => {
        setDetailTypeId(chartOfAccount.parent_id)
    })
}
</script>
