export default {
    bookeepingAccounts: {
        document_for: {
            incoming: 'Invoice for {amount}',
            outgoing: 'Bill for {amount}',
        },
        restore: 'Restore',
        restoreSuccess: 'Account(s) restored successfully',
        deleteSuccess: 'Account(s) deleted successfully',
        dated: 'Dated {date}',
        selected_transactions: '1 selected transaction',
        no_matches: 'No Transactions found',
        no_matches_desc:
            'There are no transactions matching the selected criteria.',
        categorized_manual: 'Categorized manually',
        total: 'Total',
        successfullyMatched: 'Successfully matched',
        failedToMatch: 'Failed to match',
        possible_matches: 'Possible matches',
        best_match: 'Best match',
        transactions_upto: 'Transactions up to {date}',
        title: 'Chart of Accounts',
        description: 'A list of your booking accounts',
        headerName: 'Name',
        headerAccountNo: 'Account No.',
        headerType: 'Account Type',
        headerDetailType: 'Detail Type',
        headerTax: 'Tax',
        headerCybooksBalance: 'Cybooks balance',
        searchText: 'Search by name or code',
        upsertModal: {
            title: 'Account',
            accountTypeLabel: 'Account Type',
            detailTypeLabel: 'Detail Type',
            nameLabel: 'Name',
            code: 'Code',
            descriptionLabel: 'Description',
            taxLabel: 'Tax',
            taxPlaceholder: 'Select a tax rate',
            taxHint: 'The default tax setting for this account',
            createAndClose: 'Create and close',
            updateAndClose: 'Update and close',
        },
        tooltip: {
            deletable:
                'You cannot delete this account. However, you’ll be able to edit the account details ',
        },

        detail: {
            show_details: 'Show more details',
            allAccounts: 'All Accounts',
            addNew: 'New Account',
            closingBalance: 'Closing Balance',
            description:
                'Description: Record funds received by your company yet to be deposited in a bank as undeposited funds and group them as a current asset in your balance sheet.',
            dateLabel: 'Date',
            transactionDetailsLabel: 'Transaction details',
            transactionTypeLabel: 'Transaction type',
            debitLabel: 'Debit',
            creditLabel: 'Credit',
        },
        deleteModal: {
            singleDeleteTitle: 'Delete booking account',
            singleDeleteText:
                'Are you sure you want to delete the booking account <span class="font-bold">{name}</span>?',
            bulkDeleteTitle: 'Delete accounts',
            bulkFirstText:
                'No accounts selected | You have selected 1 account to be deleted: | You have selected {n} accounts to be deleted:',
            bulkDeleteText:
                'No accounts selected | Account will be <span class="font-bold text-danger-700">Deleted</span> | Accounts will be <span class="font-semibold text-danger-700">Deleted</span>',
            bulkArchiveText:
                'No accounts selected | Account cannot be deleted, so it will be <span class="font-semibold text-primary-700">Archived</span> | Accounts cannot be deleted, so it will be <span class="font-semibold text-primary-700">Archived</span>',
            bulkSecondText:
                'This account will be removed from any contacts using it as a default account.',
            bulkNoteText:
                '<span class="font-semibold">Note:</span> Deleting Accounts is permanent and cannot be undone. Archived Accounts can be restored at any time.',
        },
    },
}
